import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h3: "h3",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  }, {StarIcon, StatsCard, StatsTile} = _components;
  if (!StarIcon) _missingMdxReference("StarIcon", true);
  if (!StatsCard) _missingMdxReference("StatsCard", true);
  if (!StatsCard.Alert) _missingMdxReference("StatsCard.Alert", true);
  if (!StatsCard.Tile) _missingMdxReference("StatsCard.Tile", true);
  if (!StatsTile) _missingMdxReference("StatsTile", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "TODO: the spec implies some extra logic paging that is left un-emplemented for now"
    }), "\n", _jsx(_components.code, {
      preview: true,
      hideSource: true,
      className: "language-jsx",
      code: `<StatsCard title="Weapons">
  {new Array(5).fill(0).map((_,i) => (
    <StatsCard.Tile key={i} title="Title/Name" subtitle="Subtitle" firstStat="First Stat" secondStat="Second Stat" />
  ))}
</StatsCard>`,
      children: _jsx(StatsCard, {
        title: "Weapons",
        children: new Array(5).fill(0).map((_, i) => _jsx(StatsCard.Tile, {
          title: "Title/Name",
          subtitle: "Subtitle",
          firstStat: "First Stat",
          secondStat: "Second Stat"
        }, i))
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type StatsCardProps = {\n  title: string;\n  className?: string;\n  /// *Should* be <StatsTiles>\n  children: React.ReactNode;\n};\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "statsalert",
      children: _jsx(_components.a, {
        href: "#statsalert",
        children: "StatsAlert"
      })
    }), "\n", _jsx(_components.code, {
      preview: true,
      hideSource: true,
      className: "language-jsx",
      code: `<StatsCard title="Weapons">
  <StatsCard.Alert text="Hint or warning" onDismiss={() => {}} />
  <StatsCard.Alert text="Hint or warning" />
  <StatsCard.Tile title="Title/Name" subtitle="Subtitle" firstStat="First Stat" secondStat="Second Stat" />
</StatsCard>`,
      children: _jsxs(StatsCard, {
        title: "Weapons",
        children: [_jsx(StatsCard.Alert, {
          text: "Hint or warning",
          onDismiss: () => {}
        }), _jsx(StatsCard.Alert, {
          text: "Hint or warning"
        }), _jsx(StatsCard.Tile, {
          title: "Title/Name",
          subtitle: "Subtitle",
          firstStat: "First Stat",
          secondStat: "Second Stat"
        })]
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type StatsAlertProps = {\n  text: string | React.ReactNode;\n  onDismiss?(): void;\n};\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "statstile",
      children: _jsx(_components.a, {
        href: "#statstile",
        children: "StatsTile"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Uses the ", _jsx(_components.a, {
        href: "/docs/component-avatar",
        children: _jsx(_components.code, {
          children: "<SquareAvatar>"
        })
      }), " component under the hood. See it's docs for more info on the ", _jsx(_components.code, {
        children: "avatar"
      }), " prop."]
    }), "\n", _jsxs(_components.code, {
      preview: true,
      hideSource: true,
      className: "language-jsx",
      code: `<StatsTile avatar={<StarIcon />} title="S.T.A.R.S. Alpha" subtitle="Raccoon City's Finest" firstStat="100% Success" secondStat="100% Casulties" />
<StatsTile avatar="https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg" title="S.T.A.R.S. Bravo" firstStat="100% Success" />`,
      children: [_jsx(StatsTile, {
        avatar: _jsx(StarIcon, {}),
        title: "S.T.A.R.S. Alpha",
        subtitle: "Raccoon City's Finest",
        firstStat: "100% Success",
        secondStat: "100% Casulties"
      }), _jsx(StatsTile, {
        avatar: "https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg",
        title: "S.T.A.R.S. Bravo",
        firstStat: "100% Success"
      })]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type StatsTileProps = {\n  title: string;\n  subtitle?: string;\n  avatar: string | React.ReactNode;\n  href?: string;\n  firstStat?: string;\n  secondStat?: string;\n  className?: string;\n};\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
